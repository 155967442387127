.login .login-thumbnail {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  border-radius: 8px;
}

.login .login-info {
  background-color: #fff;
  padding: 24px 1rem;
  border-radius: 10px;
  margin-bottom: 24px;
}

.login-header {
  padding-top: 1rem;
  color: #fff;
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
}

.login .modal-level-title {
  background-color: rgba(0, 0, 0, 0.33);
  padding: 1rem;
  margin-bottom: 24px;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: center;
}

.login-thumbnail2 {
  width: 107px;
  height: 75px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.login-thumbnail2 img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center center;
}

.login-title {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  color: rgba(33, 37, 41, 1);
}

.login .footer-login {
  font-size: 0.75rem;
  font-family: "Inter";
}

.login .back-button {
  appearance: none;
  padding: 0.5em;
  background: transparent;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin: 1rem auto;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  font-size: 1.25rem;
  font-weight: bold;
}

.login .back-button img {
  width: 24px;
}

.offer-options {
  margin-block: 1.25rem;
}

.offer-options .label {
  color: #212529;
  font-weight: var(--semibold);
}

.offer-options .offer {
  display: grid;
  grid-template-columns: minmax(0, 1fr) max-content;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  border: 1px solid #e1e1e1;
  margin-block: 1.25rem;
  padding: 1.25rem;
}

.offer-options .offer.pack {
  padding: 1.5rem 1.5rem 1rem;
}

.offer-options .offer.selected {
  background-color: #fffaf4;
  border: 1px solid #fe9e44;
}

.offer-options .offer label {
  font-weight: var(--semibold);
  color: #000000;
}

.offer-options .offer p {
  margin: 0px;
}

.offer-options .offer .pack-rate {
  font-size: 1.125rem;
}

.offer-options .offer input[type="radio"] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23fff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e");
  margin: 0px;
}

.offer-options .offer input[type="radio"]:checked {
  background-color: rgb(var(--offer-color));
  border-color: rgb(var(--offer-color));
  box-shadow: 0 0 0 0.25rem rgba(var(--offer-color), 0.25);
}

.offer-options .offer .badge {
  --bs-border-radius: 4px;
  padding: 0.33em 0.8em;
}