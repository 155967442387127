.box-wrapper {
  max-width: 480px;
}
.main {
  position: relative;
  z-index: 99;
}

.header-container {
  display: grid;
  grid-template-columns: 32px minmax(0, 1fr) 32px;
  align-items: center;
}

.icon-button {
  appearance: none;
  border: none;
  background: none;
  padding: 0px;
}

.header {
  margin-top: 32px;
  margin-bottom: 24px;
}
/* .logo {
    width: 110px;
    height: 40px;
    gap: 5px;
} */
a {
  color: inherit;
  text-decoration: none;
}
.search {
  color: #fff;
  width: 37px;
  height: 37px;
  text-align: center;
  line-height: 32px;
  border-radius: 50%;
  display: inline-block;
}
.search-input::placeholder {
  padding-left: 10px;
  font-family: Roboto;
}
.btnSearchBox {
  display: flex;
  flex-direction: row-reverse;
  gap: 5px;
}
.search-input {
  border-radius: 10px;
  border: none;
  width: 100%;
  box-shadow: none;
}
.align-items-center {
  align-items: center !important;
}
.logo-name {
  font-family: Zen Dots;
  font-size: 24px;
  font-weight: 400;
  line-height: 29px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(255, 255, 255, 1);
}

.home-profile {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 24px;
}

.avatar {
  width: 69px;
  height: 69px;
  border-radius: 50%;
  display: inline-block;
  flex-shrink: 0;
}
.avatar img {
  /*object-fit: contain;*/
  object-position: center center;
  width: 69px;
  height: 69px;
  border-radius: 50%;
}
.home-profile-name {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
  color: rgba(33, 37, 41, 1);
}
.home-profile-points {
  color: linear-gradient(0deg, #47c475, #47c475),
    linear-gradient(0deg, #ffffff, #ffffff);

  border-radius: 100px;
  padding: 6px 9px;
  display: inline-block;
  font-size: 0.875rem;
  vertical-align: middle;
  border: 1px solid rgba(71, 196, 117, 1);
  display: flex;
  align-items: center;
  gap: 6px;
}
.home-profile-points-badge {
  background-color: #fff;
  color: #fe9e44;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
.points-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(33, 37, 41, 1);
}

.featured-quiz {
  background-color: #fff;
  padding: 10px 1rem;
  border-radius: 10px;
  margin-bottom: 24px;
}
.section-title {
  /* font-family: Roboto; */
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(0, 0, 0, 1);
}
.quiz-box {
  display: block;
  border-radius: 8px;
  margin-bottom: 1rem;
  color: #0c092a;
  text-align: center;
  text-decoration: none;
}
.quiz-box img {
  max-width: 100%;
  border-radius: 10px;
  height: 72px;
  width: 72px;
  /* background: #000044; */
  background: linear-gradient(
    180deg,
    #e30000 0%,
    #920707 48.08%,
    #450000 101.99%
  );
  padding: 12px;
}
.quiz-box-title {
  text-align: center;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  margin-bottom: 0;
  font-weight: 400;
  white-space: pre-wrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  word-break: break-word;
  color: rgba(33, 37, 41, 1);
}
.featured-quiz .button-primary {
  background: #e60000;
  /* background: #000044;  */
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
.featured-quiz .button-primary:hover {
  /* background: #000044;  */
  background: #820000;
  color: #fff;
}

.page-title {
  display: block;
  /* background-color: #0A58CA; */
  /* background: rgba(21, 78, 129, 1); */
  background: linear-gradient(180deg, #1e0101 0%, #450000 101.99%);
  color: #fff;
  border-radius: 10px;
  padding: 8px 20px 8px 55px;
  position: relative;
  font-size: 1.125rem;
  margin-bottom: 24px;
}

.page-title-circle {
  position: absolute;
  display: inline-block;
  width: 43px;
  height: 43px;
  line-height: 37px;
  border-radius: 10px;
  text-align: center;
  color: #fff;
  background: #fe9e44;
  background: linear-gradient(160deg, #ffcc5f 35%, #fe9e44 100%);
  font-size: 1.25rem;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  box-shadow: 0 3px 6px rgba(12, 9, 42, 0.15);
}
.card-simple-outer {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 24px;
}
.quiz-list-wrapper {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
.quiz-list-wrapper .quiz-list {
  list-style: none;
  display: block;
  padding: 1rem;
  border-bottom: 1px solid rgba(150, 150, 150, 0.25);
  position: relative;
}
.quiz-list-wrapper .quiz-list a {
  color: #0c092a;
  text-decoration: none;
}
.quiz-list-wrapper .quiz-list::before {
  content: url("../../../public/image/Frame.png");
  font-family: "quizy-flash";
  font-style: normal;
  font-weight: normal;
  position: absolute;
  right: 1.25rem;
  color: rgba(150, 150, 150, 0.25);
  top: 50%;
  transform: translateY(-50%);
}
.quiz-list-wrapper .quiz-list .quiz-list-pict {
  flex-shrink: 0;
  width: 58px;
  height: 58px;
  display: inline-block;
  border-radius: 5px;
  /* background: #000044; */
  background: linear-gradient(
    180deg,
    #e30000 0%,
    #920707 48.08%,
    #450000 101.99%
  );
}
.quiz-list-wrapper .quiz-list .quiz-list-pict img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  object-position: center center;
  border-radius: 5px;
  padding: 8px;
}
.quiz-list-wrapper .quiz-list .quiz-list-info {
  flex-grow: 1;
  margin-left: 0.75rem;
  padding-right: 1.75rem;
  max-width: 80%;
}
.quiz-list-wrapper .quiz-list .quiz-list-info-title {
  font-size: 1.125rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.quiz-list-wrapper .quiz-list .quiz-list-info p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 16px;
  color: rgba(108, 117, 125, 1);
}

/* Leaderboard */
.leaderboard-list-wrapper {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
.leaderboard-list-wrapper .leaderboard-list {
  list-style: none;
  display: block;
  padding: 1rem;
  border-bottom: 1px solid rgba(150, 150, 150, 0.25);
  position: relative;
}
.leaderboard-list-wrapper .leaderboard-list a {
  color: #0c092a;
  text-decoration: none;
}

.leaderboard-list-wrapper .leaderboard-list .leaderboard-list-pict {
  flex-shrink: 0;
  width: 50px;
  height: 50px;
  display: inline-block;
  border-radius: 5px;
}
.leaderboard-list-wrapper .leaderboard-list .leaderboard-list-pict img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
  border-radius: 5px;
}
.leaderboard-list-wrapper .leaderboard-list .leaderboard-list-info {
  flex-grow: 1;
  margin-left: 0.75rem;
  padding-right: 1.75rem;
  max-width: 80%;
}
.leaderboard-list-wrapper .leaderboard-list .leaderboard-list-info-title {
  font-size: 1.125rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
}
.leaderboard-list-wrapper .leaderboard-list .leaderboard-list-info p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #6c757d;
}
.leaderboard-list-wrapper .leaderboard-list .leaderboard-list-info p span {
  margin-left: 10px;
}
.leaderboard-score span {
  border-radius: 10px;
  border: 1px solid var(--Border, #e1e1e1);
  background: #f1f3f3;
  display: flex;
  padding: 4px 10px;
  justify-content: center;
  align-items: center;
  gap: 6px;
  margin-top: 5px;
}
.leaderboard-score span img {
  width: 16px;
  height: 16px;
}
.leaderboard-list-pict span {
  background: #47c475;
  color: #fff;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 50%;
  padding: 1px 5px;
  position: relative;
  top: -18px;
  left: 38px;
}
/* Leaderboard */

/* Profile */
.content-box {
  background-color: #fff;
  border-radius: 10px;
  margin-bottom: 24px;
}
.profile-list-wrapper {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
.profile-list {
  list-style: none;
  display: block;
  padding: 14px;
  border-bottom: 1px solid rgba(150, 150, 150, 0.25);
  position: relative;
}
.profile-list::before {
  content: url("../../../public/image/Frame.png");
  font-family: "quizy-flash";
  font-style: normal;
  font-weight: normal;
  position: absolute;
  right: 1.25rem;
  color: rgba(150, 150, 150, 0.25);
  top: 50%;
  transform: translateY(-50%);
}

.profile-list:hover {
  cursor: pointer;
}

.profile-list a {
  color: #0c092a;
  text-decoration: none;
}
.profile-list-pict {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.profile-list-pict img {
  width: 30px;
  height: 30px;
}
.profile-list-pict img.game-icon {
  height: 25px;
}

.profile-list-info {
  margin-left: 0.75rem;
  max-width: 80%;
  display: flex;
  align-items: center;
}
.profile-list-info-title {
  font-size: 1.125rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.profile-list-info p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  color: #6c757d;
}
.profile-info {
  font-family: Roboto;
  font-size: 20px;
  font-weight: 500;
  line-height: 5px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 95%;
  color: rgba(33, 37, 41, 1);
  padding-top: 15px;
}
h4.profile-name {
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  margin-bottom: 2px;
}
.profile-info p {
  color: #6c757d;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
span.profile-subscriber {
  display: inline-flex;
  padding: 6px 14px;
  justify-content: flex-end;
  align-items: center;
  gap: 6px;
  border-radius: 100px;
  background: #47c475;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.profile-unsubscribe {
  padding: 6px 20px;
  justify-content: center;
  align-items: flex-start;
  font-weight: 600;
  color: #fff;
  border-radius: 8px;
  background: #969696;
  margin-top: 1rem;
  margin-bottom: 0px;
  appearance: none;
  border: none;
}
/* Profile */

/* History */

.history-list-wrapper {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
}
.history-list {
  list-style: none;
  display: flex;
  padding: 1rem;
  border-bottom: 1px solid rgba(150, 150, 150, 0.25);
  position: relative;
}
.history-list-pict {
  flex-shrink: 0;
  width: 58px;
  height: 58px;
  display: inline-block;
  border-radius: 5px;
  /* background: #000044; */
  background: #820000;
}
.history-list-pict img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
  object-position: center center;
  border-radius: 5px;
  padding: 8px 0;
}
.history-list-info {
  flex-grow: 1;
  margin-left: 0.75rem;
  padding-right: 1.75rem;
  max-width: 80%;
}
.history-list-info-title {
  font-size: 1.125rem;
  margin-bottom: 0.25rem;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.history-list-info p {
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #6c757d;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.history-event span {
  color: #6c757d;
  text-align: right;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.history-event img {
  width: 16px;
  height: 16px;
  margin-top: -4px;
  margin-right: 2px;
}
.history-event {
  padding-top: 18px;
}
.edit-profile {
  background-color: #fff;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: 24px;
}
.user-avatar img.profile {
  border-radius: 50%;
}
.delete-avatar {
  background: #f2545b;
  border-radius: 50%;
  padding: 3px 3px;
  display: inline-grid;
  margin-left: -20px;
  position: absolute;
  margin-top: 45px;
}
.delete-avatar img.delete {
  width: 16px;
  height: 16px;
}

.choose-file-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.choose-file {
  border: none;
  color: #47c475;
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: #fff;
  margin-left: -5px;
}
.cloud_upload {
  width: 16px;
  height: 16px;
}
.edit-profile-name {
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.myChoosefile {
  opacity: 0;
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 35px;
}
.avatar-title {
  padding-top: 10px;
}
.profile-setting label {
  color: #6c757d;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 5px;
}
.profile-setting button.btn.btn-primary {
  width: 100%;
  margin-top: 10px;
  color: #fff;
  text-align: center;
  font-family: Roboto;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  height: 48px;
}
.profile-setting hr {
  margin-left: -15px;
  width: 107%;
  padding: 0;
  height: 2px;
  border-width: 0;
  color: #e1e1e1;
  background-color: #e1e1e1;
}

/* History */

h4.policy-header {
  color: #212529;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.policy-description {
  display: grid;
  grid-template-columns: max-content minmax(0, 1fr);
  gap: 4px;
}

.languageForm {
  --background: #ffffff;
  --text: #414856;
  --radio: #7c96b2;
  --radio-checked: #4f29f0;
  --radio-size: 24px;
  --border-radius: 10px;
  background: var(--background);
  width: var(--width);
  height: var(--height);
  border-radius: var(--border-radius);
  color: var(--text);
  position: relative;
}

.choose-option {
  /* display: flex;
    justify-content: space-between; */
  border: 1px solid #e1e1e1;
  border-radius: 10px;
  color: #6c757d;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 10px 10px;
  margin-bottom: 10px;
}
.language-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.languageForm label {
  cursor: pointer;
}

.languageForm input[type="radio"] {
  appearance: none;
  position: relative;
  height: var(--radio-size);
  width: var(--radio-size);
  outline: none;
  margin: 0;
  cursor: pointer;
  border: 2px solid #2871fb;
  background: transparent;
  border-radius: 50%;
  display: grid;
  justify-self: end;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  transition: border 0.5s ease;
}

.languageForm input[type="radio"]::before,
.languageForm input[type="radio"]::after {
  content: "";
  display: flex;
  justify-self: center;
  border-radius: 50%;
}

.languageForm input[type="radio"]::before {
  position: absolute;
  width: 100%;
  height: 100%;
  background: var(--background);
  z-index: 1;
  opacity: var(--opacity, 1);
}

.languageForm input[type="radio"]::after {
  position: relative;
  width: calc(100% / 2);
  height: calc(100% / 2);
  background: var(--radio-checked);
  top: var(--y, 100%);
  transition: top 0.5s cubic-bezier(0.48, 1.97, 0.5, 0.63);
}

.languageForm input[type="radio"]:checked {
  --radio: #2871fb;
}

.languageForm input[type="radio"]:checked::after {
  --y: 0%;
  animation: stretch-animate 0.3s ease-out 0.17s;
}

.languageForm input[type="radio"]:checked::before {
  --opacity: 0;
}

.languageForm input[type="radio"]:checked ~ input[type="radio"]::after {
  --y: -100%;
}

.languageForm input[type="radio"]:not(:checked)::before {
  --opacity: 1;
  transition: opacity 0s linear 0.5s;
}

@keyframes stretch-animate {
  0% {
    transform: scale(1, 1);
  }

  28% {
    transform: scale(1.15, 0.85);
  }

  50% {
    transform: scale(0.9, 1.1);
  }

  100% {
    transform: scale(1, 1);
  }
}

.contact-us h3.choose-title {
  color: #212529;
}
.contact-us p {
  color: #6c757d;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.contact-us a.info {
  width: 100%;
  display: block;
  border: 1px solid #2871fb;
  border-radius: 10px;
  color: #2871fb;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 15px;
  padding: 10px 8px;
}
.contact-us a.info img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-top: -4px;
}
.phone-number-info {
  display: flex;
}
.react-custom-flag-select__select__wrapper {
  border: 1px solid #e1e1e1;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: auto !important;
}
.react-custom-flag-select__select__dropdown {
  padding: 0px 5px;
}
.react-custom-flag-select__select__dropdown-icon {
  margin-left: 5px;
}
.react-custom-flag-select__select__dropdown-flag {
  border-radius: 50%;
  height: 20px;
}
input.form-control.form-mobile-no {
  border-radius: inherit;
  border-radius: 10px;
  margin-left: -1px;
  height: 49px;
}

.react-custom-flag-select__select__dropdown-name.react-custom-flag-select__ellipsis {
  font-size: 16px;
}
.react-custom-flag-select__select__dropdown-flag img {
  height: 24px !important;
  width: 24px !important;
  margin-top: 14px;
}
input.form-control.form-mobile-no:focus {
  box-shadow: none;
  border-color: #e1e1e1;
}

.send_otp {
  text-align: right;
}
.send_otp_link {
  font-size: 14px;
  font-weight: 500;
  color: #47c475;
}
.form-otp {
  width: 20% !important;
  text-align: center;
}

.otp_section div {
  justify-content: space-between;
}
.otp_section div span {
  visibility: hidden;
}
.btn-pm-custom {
  width: 100%;
  margin-top: 15px;
}
.resend_otp {
  text-align: right;
  margin-top: 8px;
}
.react-custom-flag-select__select__selector
  .react-custom-flag-select__select__dropdown-name
  div:nth-child(1)::before {
  content: "+";
}
.user-avatar img.profile {
  height: 70px;
  width: 70px;
}
.multiple-question.multiple-question-image img {
  height: 220px;
  width: 100%;
}
.logo img {
  width: 148px;
}

.home-quiz .col-3 {
  flex: 0 0 auto;
  width: 20%;
}

.react-multi-carousel-track .col-3 {
  flex: 0 0 auto;
  width: 100%;
}
.react-multi-carousel-dot button {
  border-color: #d9d9d9 !important;
}
.react-multi-carousel-dot--active button {
  /* background: #000044 !important;  */
  background: #820000 !important;
  width: 20px !important;
  border-radius: 100px !important;
}

.quiz-info-meta-level {
  padding: 6px 10px 6px 10px;
  border-radius: 100px;
  font-size: 0.875rem;
  margin-bottom: 1rem;
  height: 53px;
  width: 100%;
  gap: 8px;
  background: rgba(254, 158, 68, 0.1);
}
.level-page .button-primary {
  /* background: #000044; */
  background: #820000;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
  margin-top: 15px;
}
.level-page .button-primary:hover {
  /* background: #000044;  */
  background: #820000;
  color: #fff;
}
@media only screen and (max-width: 600px) {
  .quiz-box img {
    max-width: 100%;
    border-radius: 10px;
    height: 57px;
    width: 57px;
    /* background: #000044; */
    background: #820000;
    padding: 12px 0;
  }
  .react-multi-carousel-track .col-3 {
    flex: 0 0 auto;
    width: 85%;
  }
}

.align-items-baseline.active {
  border: 2px solid #ffc107;
}

.level-page .page-header {
  padding-bottom: 1.5rem !important;
}
.footer-login {
  text-align: center;
  margin-top: 15px;
}

#randomText {
  color: #6c757d;
  font-family: Roboto;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 15px;
  padding: 0px 8px;
}

.custom-heading-image {
  height: 50px;
}
.custom-heading {
  display: flex;
}

.did-you-know {
  display: flex;
  align-items: center;
  text-align: center;
  margin: 0 auto;
  width: 100%;
}

.icon {
  width: 75px;
  height: 75px;
  object-fit: contain;
  margin-right: 20px;
}

.did-you-know h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.fact {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 10px;
}

.description {
  font-size: 18px;
  line-height: 1.5;
  color: #fff;
}
.custom-fact-fun {
  padding: 0;
  background: #0000;
}
.ft-65 {
  font-size: 40px;
}
img.rocket {
  width: 294px;
  height: 196px;
}
.cg-wine {
  font-weight: 600;
}
.custom-campionship {
  padding: 5% 5% 5% 5% !important;
}

h3.competition-title {
  text-transform: uppercase;
  margin: 20px 0;
  font-weight: 500;
  font-size: 30px;
}
.competition-description {
  font-weight: 500;
}
.competition-image .col-md-3 {
  width: 30%;
  margin-left: -15px;
}
.competition-image .col-md-9 {
  width: 70%;
}
.competition-image {
  background: #fff;
  padding: 15px 30px 20px 25px;
}
.competition-img img {
  height: 85px;
  width: 100%;
  border: 2px solid #820000;
  border-radius: 10px;
  background: #000;
}
.react-multi-carousel-list {
  border-radius: 10px;
}
.competition-details .description {
  overflow: hidden;
  line-clamp: 2;
  text-overflow: ellipsis;
  color: #000;
  font-size: 12px;
}
.competition-details .title {
  font-weight: 600;
  text-transform: capitalize;
}
.competition-details .timer span {
  font-size: 13px;
  color: #000;
  font-weight: 500;
}
img.chronometer {
  height: 15px;
  width: 15px;
  margin-top: 6px;
}
.competition-details .timer {
  display: flex;
}
.countdown-timer {
  margin-left: 8px;
}
li.camp-list {
  background: #fff;
  margin-bottom: 10px;
  padding: 10px 25px;
  border-radius: 10px;
  display: flex;
  box-shadow: rgb(48 1 1) 0px 20px 30px -10px;
}
.camp-list::after {
  content: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADUSURBVHgB7ZbBCcMgGIW1h4TcMkJGcIWM0BEcwUk8iUdH6Aiu0BE6Qo56sr+QQ6E99DevlIAfSBIJvC+RhwrR6XTOhrVW0ZgFiAvn5Ro+DEMcxzGiJFgCFByklDVYoSRYAhR+pctjf4RISMHEe7+UUiLdLvvUPee8GmM20QBbAC3RJICUaBZASRwSQEiwWvCJlNLb3Dx/X4xDf4AquNQqipevn6Zp1Vr/fgkQ4c0CqPAmAWQ4WwAdXuFuRjdkOFuA+q5pbKjwJpxzKoQAO5B0Op2/8wT+cYAO6JDB8AAAAABJRU5ErkJggg==);
  position: relative;
  margin-top: 6%;
  margin-right: -5%;
}
.camp-img img {
  height: 85px;
  width: 100%;
  border: 2px solid #820000;
  border-radius: 10px;
  background: #000;
}
.camp-list .comp-li {
  display: grid;
  grid-template-columns: 3fr 7fr;
  align-items: center;
  gap: 1rem;
}

.camp-description {
  overflow: hidden;
  line-clamp: 2;
  text-overflow: ellipsis;
  color: #000;
  font-size: 12px;
}

.camp-content .timer {
  display: flex;
}

.camp-content .timer span {
  font-size: 12px;
  color: #000;
  font-weight: 400;
  top: 2px;
  position: relative;
}

.camp-list-wrapper {
  padding-left: 0;
  padding-right: 0;
  margin: 0;
  list-style-type: none;
}
.competition-details-page {
  background: #fff;
  border-radius: 10px;
  padding: 15px 10px;
}

.competition-details-page .camp-img img {
  height: 200px;
  width: 100%;
  border: 2px solid #f9f9f9;
  border-radius: 10px;
  background: #000;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}
.competition-details-page .camp-title {
  font-size: 25px;
  font-weight: 700;
  text-align: center;
}
.competition-details-page .timer {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
}

.competition-details-page .timer span {
  font-size: 20px;
  color: #000;
  font-weight: 400;
  top: 0px;
  position: relative;
}
.competition-details-description {
  font-weight: 400;
  text-align: left;
}
.start-competition {
  background: #e60000 !important;
  color: #fff;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}
.start-competition:hover {
  background: #820000 !important;
  color: #fff !important;
}
.competition-details-page .camp-content {
  padding: 15px 0;
}
.competition-details-page img.chronometer {
  height: 20px;
  width: 20px;
  margin-top: 6px;
}
.competition-already,
.competitionStart {
  text-align: center;
  color: green;
  font-size: 18px;
  font-weight: 700;
}
.begin_the_competition {
  color: green;
  font-weight: 600;
  font-size: 14px;
}
.competition-complete {
  color: #930808;
  text-align: center;
  font-weight: 700;
  font-size: 20px;
}

.padding-title {
  padding: 8px 20px 8px 20px;
}
.leaderboard-list-won .leaderboard-list {
  padding: 0.4rem;
}

.leaderboard-list-won .leaderboard-list .leaderboard-list-pict img {
  border: 1px solid #cecaca;
  border-radius: 50%;
}
.w-33 {
  width: 33%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 0;
}
.leaderboard-score.w-33.text-right {
  float: right;
  width: 20%;
}
.user-name-comp {
  padding-left: 5px;
}
.your-rank {
  background: #fff;
  margin-bottom: 10px;
  padding: 0.4rem;
  border-radius: 10px;
}
.rank-of-user {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.duration-Time span {
  margin-top: 0;
}
