.page-header {
  padding-top: 3rem;
  padding-bottom: 4rem;
  color: #fff;
  font-weight: bold;
}
.loss-wrap {
  zoom: 82%;
}
.quiz-result-status {
  color: #fff;
  text-transform: uppercase;
  font-weight: bold;
  text-shadow: 0 3px 10px rgba(12, 9, 42, 0.1);
  margin-bottom: 0.5rem;
  font-family: Roboto;
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  letter-spacing: 0em;
  text-align: center;
}
.quiz-result-pict {
  width: 150px;
  height: 150px;
  max-width: 100%;
  /* background-image: url(../images/result-pict-bg.svg); */
  background-size: contain;
  margin: auto;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 0.5rem;
  position: relative;
}
.quiz-result-pict-ava {
  width: 220px;
  display: inline-block;
  border-radius: 50%;
}
.quiz-result-pict-ava img {
  height: 220px;
  width: 220px;
  object-fit: cover;
  object-position: center center;
  border-radius: 50%;
}

button.quiz-result-ann {
  display: block;
  color: #ffffff;
  font-size: 1.25rem;
  margin: 1rem auto;
  font-weight: 500;
  text-align: center;
}

.quiz-result-points {
  background-color: #fff;
  color: #0c092a;
  border-radius: 50rem;
  padding: 8px 14px;
  display: inline-block;
  font-size: 1rem;
  vertical-align: middle;
  font-weight: 500;
  width: 172px;
  height: 46px;
  padding-top: 10px;
}
.quiz-result-points-badge {
  background-color: #fe9e44;
  color: #fff;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 12px;
}
.final-score {
  background-color: #ffffff;
  box-shadow: 0 5px 10px rgba(12, 9, 42, 0.16);
  border-radius: 10px;
  margin-bottom: 24px;
}
.final-score .final-score-title {
  color: #fff;
  padding: 8px 16px;
  border-radius: 12px;
  box-shadow: 0 5px 10px rgba(255, 204, 95, 0.16);
  font-family: Roboto;
  font-size: 16px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: center;
  text-transform: uppercase;
}
.final-score-top {
  padding: 24px 24px 12px 24px;
  color: #0c092a;
}
.final-score-top-box {
  color: #fff;
  padding: 0.5rem;
  font-size: 1.5rem;
  border-radius: 100px;
  font-weight: bold;
  background: rgba(40, 113, 251, 1);
}
.final_name {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
}
.final-score-top-box2 {
  color: #fff;
  padding: 0.5rem;
  font-size: 1.5rem;
  border-radius: 100px;
  font-weight: bold;
  background: rgba(236, 64, 50, 1);
}
.final-score-detail {
  padding: 12px 24px 12px 24px;
  border-top: 1px solid rgba(160, 148, 137, 0.5);
  color: #a09489;
}
.final-score-detail-title {
  margin-bottom: 0;
  position: relative;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  cursor: pointer;
}
.final-score-detail-title span {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  font-size: 1.125rem;
}
.final-score-more {
  padding-top: 12px;
  font-size: 0.875rem;
  color: #0c092a;
  padding-left: 0;
  padding-right: 0;
}
.final-score-more {
  list-style: none;
  border-top: 1px dashed rgba(160, 148, 137, 0.5);
  padding: 12px 0 12px 0;
}
.quiz-result-points-badge img {
  width: 35px;
  height: 35px;
}
.final-score-more .score-stats-badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  line-height: 1.5;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
  margin-right: 0.25rem;
  min-width: 35px;
  color: rgba(71, 196, 117, 1);
  border: 1px solid rgba(71, 196, 117, 1);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}
.final-score-more .score-stats-badge2 {
  display: inline-block;
  padding: 0.35em 0.65em;
  line-height: 1.5;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 10px;
  margin-right: 0.25rem;
  min-width: 35px;
  color: rgba(242, 84, 91, 1);
  border: 1px solid rgba(242, 84, 91, 1);
  font-family: Roboto;
  font-size: 14px;
  font-weight: 700;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: center;
}
.final-card {
  background: linear-gradient(0deg, #1178a8, #1178a8),
    linear-gradient(0deg, #6c757d, #6c757d);
  border-radius: 10px 10px 0px 0px;
}
.right-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(150, 150, 150, 1);
}
.wrong-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(150, 150, 150, 1);
}
.p_color {
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: right;
  color: rgba(108, 117, 125, 1);
}
@media (max-width: 400px) {
  .loss-wrap {
    zoom: 77%;
  }
}
@media (min-width: 400px) and (max-width: 992px) {
  .loss-wrap {
    zoom: 90%;
  }
}
