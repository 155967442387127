.page-header-score {
    color: #fff;
    padding-top: 3rem;
    padding-bottom: 24px;
}
.adjestment{
    padding: 0px 24px;
}
.quiz-player {
    display: flex;
}
.quiz-player-avatar {
    flex-shrink: 0;
    border-radius: 50%;
    height: 45px;
    height: 45px;
    display: inline-block;
}
.quiz-player-avatar img {
    object-fit: contain;
    object-position: center center;
    width: 69px;
    height: 69px;
    border-radius: 50%;
}
.quiz-player-info {
    flex-grow: 1;
}
.countdown {
    position: relative;
    margin: auto;
    margin-top: 0.25rem;
    height: 40px;
    width: 40px;
    text-align: center;
}
.countdown #countdown-number {
    color: #ffffff !important;
    display: inline-block;
    line-height: 40px;
    font-weight: bold;
    padding-top: 16px;
    font-family: Roboto;
    font-size: 22px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0em; 

}
.available{
    font-family: Roboto;
    text-align: center;
    font-size: 20px;
    color: #ffffff;
    font-weight: bold;
    padding-top: 1rem;
}
.countdown svg {
    position: absolute;
    top: -5px;
    right: -14px; 
    transform: rotateY(-180deg) rotateZ(-90deg);
}
.swipe.loaded {
    opacity: 1;
}
.swipe {
    min-height: calc(100vh - 96px);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    opacity: 0;
    transition: opacity 0.1s ease-in-out;
}
.swipe-action {
    position: absolute;
    top: 50%;
    margin-top: -30px;
    z-index: 2;
    width: 100%;
    pointer-events: none;
    left: 0;
    right: 0;
    text-align: center;
}
.swipe-action-circle.love {
    background-color: rgba(0,128,0,0.35);
}
.swipe-action-circle {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    font-size: 1.75rem;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}
.swipe-action-circle.nope {
    background-color: rgba(255,0,0,0.35);
}
.swipe-action-circle {
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    border-radius: 50%;
    font-size: 1.75rem;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    opacity: 0;
    transition: all 0.2s ease-in-out;
}
.swipe-action-circle.nope span {
    margin-top: 0.25rem;
}
.swipe-counter {
    color: #fff;  
    margin-bottom: 1rem;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: center;

}
.swipe-cards {
    flex-grow: 1;
    padding-top: 24px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 1;
}
.swipe-status {
    position: relative;
    flex: 0 0 130px;
    text-align: center;
}
.multiple {
    position: relative;
}
.multiple-question-image {
    background-color: #fff;
    display: block;
    align-items: inherit;
    justify-content: inherit;
    height: 40%;
    color: #0C092A;
}
.multiple-question {
    border-radius: 10px;
    box-shadow: 0 5px 10px rgba(12,9,42,0.16);
    display: block;
    max-width: calc(480px - 3rem);
    background: #fff;
    overflow: hidden;
    margin-left: auto;
    margin-right: auto;
}
.multiple-question-image .multiple-question-content {
    background-color: #fff;
    display: block;
    align-items: inherit;
    justify-content: inherit;
    height: 40%;
    color: #0C092A;
}
.multiple-question-content {
    background-color: #FE9E44;
    padding: 13px 5px 0px 5px;
    font-size: 1.3125rem;
    font-weight: bold;
    border-radius: 0 0 10px 10px;
    text-align: center;
    pointer-events: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
}
.multiple-answer {
    margin-left: 0;
    margin-right: 0;
    margin-top: 1rem;
    padding: 0;
    display: block;
    max-width: calc(480px - 3rem);
    margin-left: auto;
    margin-right: auto;
}
.multiple-answer li {
    list-style: none;
    margin-bottom: 1rem;
    position: relative;
    /* text-overflow: ellipsis; */
    display: flex;
    /* white-space: nowrap; */
}
.multiple-answer li input {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0.25rem;
    visibility: hidden;
}
.multiple-answer li label {
    background-color: #fff;
    padding: 0.85rem 0.875rem;
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 5px 10px rgba(12,9,42,0.05);
    border: 1px solid transparent;
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    height: 55px;
}
.multiple-answer li label:hover {
    background-color: #fff;
}
.multiple-answer li label .bullet { 
    font-family: Roboto;
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(108, 117, 125, 1);

}
.role-name{
    font-family: Roboto;
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0em; 
    
}
.real_score{
    font-family: Roboto;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em; 

}
.game-que{
    font-family: Roboto;
    font-size: 22px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center; 
}
.button-back{
    font-family: Roboto;
    font-size: 20px;
    font-weight: 700;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    border: 1px solid #ffffff;
    height: 44px;
    text-align: center;
    padding-top: 9px;
    border-radius: 10px;
}
.button-back:hover{
    color: rgba(255, 255, 255, 1);
    border: 1px solid #ffffff;

}
.correct {
    background-color: green !important; /* Change to your preferred green color */
}

.wrong {
    background-color: red !important; /* Change to your preferred red color */
}
.selected {
    /* Add styles for selected option */
    background-color: red; /* or any other color you prefer for a selected option */
  }
  @media (max-width: 500px) {
    .adjestment{
        padding: 0px 10px;
    }
}

.countdown-timer {
    color: #ffffff !important;
    display: inline-block;
    line-height: 40px;
    font-weight: bold;
    padding-top: 5px;
    font-family: Roboto;
    font-size: 22px;
    font-weight: 300;
    line-height: 26px;
    letter-spacing: 0em; 
}
.countdown-timer-home {
    padding-top: 0;
    margin-top: -2px;
}
.countdown-clock {
    font-family: Arial, sans-serif;
    font-size: 24px;
    color: #fff;
    text-align: center;
    position: relative;
    width: 140px;
    height: 140px;
  }
  
  .progress-ring {
    fill: rgb(33, 37, 41);
    stroke: rgb(254, 158, 68); /* Color of the progress ring */
    stroke-width: 8;
    stroke-linecap: round;
    animation: countdown-animation linear infinite; /* Animation */
    transform: rotate(-90deg); /* Start from the top */
    transform-origin: 50% 50%;
  }
  
  @keyframes countdown-animation {
    from {
      stroke-dasharray: 0 100;
    }
    to {
      stroke-dasharray: 100 100;
    }
  }
  
  .countdown-clock span {
    position: absolute;
    top: 52%;
    left: 47%;
    transform: translate(-50%, -50%);
  }
  